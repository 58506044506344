import React from "react"
import Layout from "../components/layouts/Layout2"
import { Hero, Map, Breadcrumb } from "../components/slices"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { renderMetaTags } from "../utils"
import "photoswipe/dist/photoswipe.css"

import { Gallery as GalleryHolder, Item } from "react-photoswipe-gallery"

const Gallery = ({ data, path }) => {
  const { title, content, background_image, gallery, seoMetaTags } =
    data.allDatoCmsGallery.nodes[0]
  const breadcrumbData = [
    { title: "Home", url: "/" },
    { title, url: path },
  ]

  const heroProps = {
    variation: "small",
    title,
    mainImage: background_image,
  }
  return (
    <Layout>
      {seoMetaTags.tags && <Helmet>{renderMetaTags(seoMetaTags.tags)}</Helmet>}
      <Hero {...heroProps} />
      <div className="container -pinched">
        <Breadcrumb data={breadcrumbData} />
      </div>
      <div className="[ section -small ]">
        <div className="intro">
          <div className="[ container -pinched ]">
            <p>{content}</p>
          </div>
        </div>
      </div>
      <div className="section gallery">
        <div className="container">
          <div className="gallery-grid">
            <GalleryHolder>
              {gallery &&
                gallery.map((galleryItem, index) => {
                  const { thumbnail, image, alt } = galleryItem
                  return (
                    <div className="gallery-grid__item">
                      <Item
                        original={image}
                        thumbnail={thumbnail}
                        width="610"
                        height="763"
                      >
                        {({ ref, open }) => (
                          <img ref={ref} onClick={open} src={image} />
                        )}
                      </Item>
                    </div>
                  )
                })}
            </GalleryHolder>
          </div>
        </div>
      </div>
      <Map />
    </Layout>
  )
}

export const query = graphql`
  query GalleryPage {
    allDatoCmsGallery {
      nodes {
        title
        content
        gallery {
          thumbnail: url(
            imgixParams: {
              auto: "format"
              fit: "crop"
              q: 60
              w: "305"
              h: "480"
            }
          )
          image: url(
            imgixParams: {
              auto: "format"
              fit: "crop"
              q: 60
              w: "577"
              h: "722"
            }
          )
          focalPoint {
            x
            y
          }
          alt
        }
        seoMetaTags {
          tags
        }
      }
    }
  }
`

export default Gallery
